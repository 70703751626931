import React from "react"

const GridWrapper = ({ children }) => {
  return (
    <div className="flex flex-row flex-wrap justify-center -mx-grid">
      {children}
    </div>
  )
}

export default GridWrapper
