import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft, faCaretRight } from "@fortawesome/pro-solid-svg-icons"

// import Swiper core and required modules
import { Navigation, Pagination, A11y } from "swiper"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/scss"

const SliderWrapper = ({
  children,
  className,
  _uid,
  showSlides,
  hasHeadline,
}) => {
  let showDesktopArrows = false
  if (showSlides < children?.length) {
    showDesktopArrows = true
  }

  return (
    <div
      className={`relative ${hasHeadline ? "pt-8" : ""} ${
        className?.length > 0 ? className : ""
      }`}
    >
      <div
        className={`absolute top-0 right-0 flex flex-row pb-2 transform -translate-y-full md:pb-10 ${
          showDesktopArrows ? "" : "lg:hidden"
        }`}
      ></div>
      <div
        className={`absolute z-50 top-1/2 left-0 transform -translate-x-full text-2xl flex items-center justify-center w-8 h-8 mr-4 text-primary hover:text-primary-hover swiper-button-prev-${_uid}`}
      >
        <FontAwesomeIcon icon={faCaretLeft} size="2x" className="" />
      </div>
      <div
        className={`absolute z-50 top-1/2 right-0 transform translate-x-full text-2xl flex items-center justify-center w-8 h-8 text-primary  hover:text-primary-hover swiper-button-next-${_uid}`}
      >
        <FontAwesomeIcon icon={faCaretRight} size="2x" className="" />
      </div>

      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={32} // 0 vs 32px
        slidesPerView={1} // auto vs {1}
        pagination={{
          clickable: true,
          el: ".swiper-pagination-" + _uid,
          bulletClass:
            "swiper-pagination-bullet inline-block w-4 h-4 mx-2 border md:w-6 md:h-6 border-primary hover:bg-primary",
          bulletActiveClass: "bg-primary",
        }}
        navigation={{
          disabledClass:
            "bg-white border-gray-300 text-gray-300 pointer-events-none",
          nextEl: ".swiper-button-next-" + _uid,
          prevEl: ".swiper-button-prev-" + _uid,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: showSlides,
          },
        }}
      >
        {children?.length > 0 &&
          children.map((child, index) => (
            <SwiperSlide key={index} className="mb-4">
              {child}
            </SwiperSlide>
          ))}
      </Swiper>
      <div className={`swiper-pagination-${_uid} text-center py-2`}></div>
    </div>
  )
}

export default SliderWrapper
